import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { StructuredText } from 'react-datocms';

import Section from '../../molecules/Section/Section';
import Select from '../../molecules/Inputs/Select/Select';
import Table from '../../molecules/Table';

import useLegalPages from '../../hooks/useLegalPages';

import { formatSelectItems } from './utils/helpers';

import {
  Grid,
  InnerWrapper,
  Sidebar,
  Dropdown,
  List,
  ListItem,
  Container,
} from './styles/index.styled';
import PersistLink from '../PersistLink/PersistLink';

const LegalComponent = ({ slug, component: { structuredText } }) => {
  const legalPages = useLegalPages();
  const [selectedPage, setSelectedPage] = useState('');
  const [selectValue, setSelectValue] = useState('');

  useEffect(() => {
    const page = legalPages.find(p => p.internalName === selectedPage);
    if (page) {
      navigate(`/${page.slug}`);
    }
  }, [legalPages, selectedPage]);

  useEffect(() => {
    const page = legalPages.find(p => p.slug === slug);
    if (page) {
      setSelectValue(page.internalName);
    }
  }, [legalPages, slug]);

  return (
    <Section innerStyle={InnerWrapper}>
      <Grid>
        <Sidebar>
          <p className="sidebar-heading">Topics</p>
          <Dropdown>
            <Select
              items={formatSelectItems(legalPages)}
              value={selectValue}
              setValue={setSelectedPage}
              placeholder="Privacy Policy"
              menuItemSize="small"
            />
          </Dropdown>
          <List>
            {legalPages.map(page => (
              <ListItem key={page?.id}>
                <PersistLink
                  to={`/${page?.slug}`}
                  className={page?.slug === slug ? 'active' : ''}
                >
                  {page?.internalName}
                </PersistLink>
              </ListItem>
            ))}
          </List>
        </Sidebar>
        <Container>
          <StructuredText
            data={structuredText}
            renderInlineRecord={({ record }) => {
              switch (record.__typename) {
                case 'DatoCmsMoleculeTable': {
                  return <Table tableData={record?.table} />;
                }
                default:
                  return null;
              }
            }}
          />
        </Container>
      </Grid>
    </Section>
  );
};

export default LegalComponent;
